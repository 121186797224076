//big
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
$bakbak: 'Bakbak One', cursive;

//body
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Bakbak+One&family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
$archivo: 'Archivo', sans-serif;

//italic
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
$cormorant: 'Cormorant', serif;

$breakpoint: 992px;
$breakpoint1439: 1439px;
$breakpoint1800: 1800px;

//colors
$white: #ffffff;
$black: #000000;
$grey: #4B4B4B;
$greySeparator: #E0E0E0;


//
@custom-media --xsmall (width >= 400px);
@custom-media --small (width >= 540px);
@custom-media --medium-small (width >= 720px);
@custom-media --medium (width >= 960px);
@custom-media --medium-large (width >= 1140px);
@custom-media --large (width >= 1380px);

/* below */
@custom-media --below-xsmall (width < 400px);
@custom-media --below-small (width < 540px);
@custom-media --below-medium-small (width < 876px);
@custom-media --below-medium (width < 960px);
@custom-media --below-medium-large (width < 1140px);
@custom-media --below-large (width < 1380px);