@import 'variables';
@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');

.home-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    // height: 100vh;
    height: 100vh;  
    max-height: -webkit-fill-available;
    background: #C3C3C3;
    overflow: hidden;
}

.text-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 20px 0 0 20px;

    @media screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            top: auto;
            bottom: auto;

            &.black,
            &.g1,
            &.g2,
            &.g3,
            &.g4,
            &.g5 {
                transform: translate3d(0, 0, 0);
                transition: transform 0.3s ease;
                animation-duration: 11s;
            }

            &.black {
                animation-name: animblack;
            }

            &.g1 {
                animation-name: animg1;
            }

            &.g2 {
                animation-name: animg2;
            }

            &.g3 {
                animation-name: animg3;
            }

            &.g4 {
                animation-name: animg4;
            }

            &.g5 {
                animation-name: animg5;
            }
        }

        @keyframes animblack {
            30%   { transform: scale(1) }
            50%   { transform: scale(0.92) }
            75%   { transform: scale(108); opacity: 1; }
            76%   { transform: scale(108); opacity: 0; }
            78%   { transform: scale(0.92); opacity: 0; }
            79%  { transform: scale(1); opacity: 1; }
        }

        @keyframes animg1 {
            20%   { transform: scale(1)}
            25%   { transform: scale(1.1)}
            30%   { transform: scale(1) }
            50%   { transform: scale(1.3) }
            75%   { transform: scale(108) }
            99%   { opacity: 1; }
            100%  { transform: scale(108); opacity: 0; }
        }

        @keyframes animg2 {
            20%   { transform: scale(1)}
            25%   { transform: scale(1.12)}
            30%   { transform: scale(1) }
            50%   { transform: scale(1.6) }
            75%   { transform: scale(108) }
            99%   { opacity: 1; }
            100%  { transform: scale(108); opacity: 0; }
        }

        @keyframes animg3 {
            20%   { transform: scale(1)}
            25%   { transform: scale(1.14)}
            30%   { transform: scale(1) }
            50%   { transform: scale(1.9) }
            75%   { transform: scale(108) }
            99%   { opacity: 1; }
            100%  { transform: scale(108); opacity: 0; }
        }

        @keyframes animg4 {
            20%   { transform: scale(1)}
            25%   { transform: scale(1.16)}
            30%   { transform: scale(1) }
            50%   { transform: scale(2.2) }
            75%   { transform: scale(108) }
            99%   { opacity: 1; }
            100%  { transform: scale(108); opacity: 0; }
        }

        @keyframes animg5 {
            20%   { transform: scale(1)}
            25%   { transform: scale(1.18)}
            30%   { transform: scale(1) }
            50%   { transform: scale(2.5) }
            75%   { transform: scale(108) }
            99%   { opacity: 1; }
            100%  { transform: scale(108); opacity: 0; }
        }
    }

    &[data-isflat="true"] {
        .black,
        .g1,
        .g2,
        .g3,
        .g4,
        .g5 {
            transform: translate3d(0, 0, 0);
        }
    }
}

.bottom-text {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100vw - 48px);
    margin: 0 auto 24px;
    z-index: 10;

    span,
    a {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        color: #262626;
    }

    a {
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }
}

p {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 13vw;
    line-height: 80%;
    text-transform: uppercase;
    font-weight: bold;

    &.black,
    &.g1,
    &.g2,
    &.g3,
    &.g4,
    &.g5 {
        // transition: transform 0.3s ease;
    }
    
    &.black {
        color: #000;
        z-index: 10;
        // transition-delay: 0.05s;
    }

    &.g1 {
        transform: translate3d-(5px, 5px, 0);
        color: #F9E74D;
        z-index: 9;
        // transition-delay: 0.10s;
    }

    &.g2 {
        transform: translate3d(-10px, 10px, 0);
        color: #5ECDF9;
        z-index: 8;
        // transition-delay: 0.15s;
    }

    &.g3 {
        transform: translate3d(-15px, 15px, 0);
        color: #7CFA4C;
        z-index: 7;
        // transition-delay: 0.20s;
    }

    &.g4 {
        transform: translate3d(-20px, 20px, 0);
        color: #0208F4;
        z-index: 6;
        // transition-delay: 0.25s;
    }

    &.g5 {
        transform: translate3d(-25px, 25px, 0);
        color: #EA33DD;
        z-index: 5;
        // transition-delay: 0.30s;
    }
}